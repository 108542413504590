import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';

import { TeamGeneratorConfigResponse } from '@common/types';
import Spinner from './spinner';
import SEO from './seo';
import Generator from './generator';
import { GENERATOR_MODE_META } from './generator.const';
import useAPIHelper from '../hooks/api';
import useGlobalContext from '../hooks/global-context';
import { GeneratorMode } from '../types/generator';
import { GlobalActionType } from '../types/global-context';
import { teamGeneratorFormSchema } from '../utils/form-schema';
import useMediaQuery from '../hooks/media-query';
import MobileLanding from '../components/mobile-landing';

/**
 * Manages the dynamic render of a team generator page. The generator config is
 * first requested from the API based on teamId (route param) and then passed on
 * for the final render on success. Otherwise the user is kicked back to the
 * home screen.
 */
const TeamGenerator = ({ teamId }: TeamGeneratorProps) => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [{ mode }, dispatch] = useGlobalContext();
  const { useLambdaAPI } = useAPIHelper();
  const [{ data, loading, error }] = useLambdaAPI<TeamGeneratorConfigResponse>({
    url: '/team-generator',
    params: { teamId },
  });

  /**
   * Maintains the global app loading state whilst fetching essential data
   */
  useEffect(() => {
    dispatch({
      type: GlobalActionType.SetIsAppLoading,
      isAppLoading: loading,
    });
  }, [dispatch, loading]);

  /**
   * Sets the teamName in global state as it needs to be used in the app header
   */
  useEffect(() => {
    if (!data?.teamName) return;

    dispatch({
      type: GlobalActionType.SetTeamName,
      teamName: data.teamName,
    });
  }, [dispatch, data?.teamName]);

  /**
   * Any render of this component should force the global generator state into
   * Team Generator mode. Async callbacks further up the tree can cause the mode
   * to be reset, so this ensures those changes are reversed. When the mode is
   * correctly set we set the form schema.
   */
  useEffect(() => {
    if (mode === GeneratorMode.TeamGenerator) {
      return dispatch({
        type: GlobalActionType.SetFormValidationSchema,
        formValidationSchema: teamGeneratorFormSchema,
      });
    }

    dispatch({
      type: GlobalActionType.SetGeneratorMode,
      mode: GeneratorMode.TeamGenerator,
    });
  }, [dispatch, mode]);

  if (loading) return <Spinner isLoading={loading} />;

  // Error response from API means the subscription is inactive or the teamId
  // is invalid. Boot the user back to the index.
  if (error || !data?.teamControls) {
    navigate('/');
    return null;
  }

  const { description } = GENERATOR_MODE_META[mode];

  const title =
    typeof description === 'function'
      ? description(data.teamName)
      : 'Team Generator';

  return (
    <>
      <SEO title={title} />
      {isMobile ? <MobileLanding /> : <Generator {...data} />}
    </>
  );
};

export type TeamGeneratorProps = RouteComponentProps<{ teamId: string }>;

export default TeamGenerator;
