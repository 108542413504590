import React from 'react';
import { Match, MatchRenderProps } from '@reach/router';
import { navigate } from 'gatsby';

import TeamGenerator from '../components/team-generator';

/**
 * Team Generators are dynamic pages, rendered on demand using a config fetched
 * from their Team Admin account. To make this happen, we tell Gatsby to resolve
 * paths on `/t/*` to this page, which leverages Reach router's Match to start
 * the page render, or kicks back to the index if no teamId is supplied. This is
 * a router only - all logic for Team Generator creation is contained in the
 * TeamGenerator component.
 */
const TeamRouter = () => (
  <Match path="/t/:teamId">
    {({ match }: MatchRenderProps<{ teamId: string }>) => {
      if (!match) {
        navigate('/');
      } else {
        return <TeamGenerator teamId={match.teamId} />;
      }
    }}
  </Match>
);

export default TeamRouter;
